import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Component/Home/Home";
import VisaApplyPage from "./Component/VisaApply/VisaApplyPage";
import TravelerDetails from "./Component/StartApplication/travelerDetail/TravelerDetails";
import Document from "./Component/StartApplication/SubmitDocument/Document";
import AboutUs from "./Component/Footer/AboutUs";
import Privacy from "./Component/Footer/Privacy";
import TravelerVlogs from "./Component/TravelerVlog/TravelerVlogs";
import RequestResetPassword from "./Component/StartApplication/ForgatePassword/RequestResetPassword";
import ValidateOTP from "./Component/StartApplication/ForgatePassword/ValidateOTP";
import ResetPassword from "./Component/StartApplication/ForgatePassword/ResetPassword";
import MainDetails from "./Component/StartApplication/MainDetail/MainDetails";
import VisaContext from "./context/visa-context";
import RefundPolicy from "./Component/Footer/RefundPolicy";
import NoPage from "./Component/Nopage/NoPage";

import "./App.css";
import Dashboard from "./Component/UsersDashboard/pages/Dashboard/Dashboard";
import UserApplicatons from "./Component/UsersDashboard/pages/UserPage/UserApplicatons";
import TermAndConditions from "./Component/Footer/TermAndConditions";
import OtherCountris from "./Component/Footer/OtherCountris";
import Success from "./Component/StartApplication/MainDetail/Payment/Success";
import Failure from "./Component/StartApplication/MainDetail/Payment/Failure";
import VisasFiles from "./Component/UsersDashboard/pages/UserPage/VisasFiles";
import Freelancer from "./Component/Footer/Freelancer/Freelancer";
import RegisterPaySuccess from "./Component/StartApplication/Register/RegisterPaySuccess";
import RegisterFailure from "./Component/StartApplication/Register/RegisterFailure";
import Register from "./Component/StartApplication/Register/Register";
import LoginForm from "./Component/StartApplication/Login/LoginForm";
import WorkCountryCards from "./Component/UsersDashboard/pages/Dashboard/WorkPermitVisa/WorkCards/WorkCountryCards";
import WorkDetails from './Component/UsersDashboard/pages/Dashboard/WorkPermitVisa/WorkDetails/WorkDetails.jsx';

import StartApplication from "./Component/UsersDashboard/pages/Dashboard/WorkPermitVisa/ApplicationStart/StartApplication";
import ApplicantCheckOutPage from "./Component/UsersDashboard/pages/Dashboard/WorkPermitVisa/CheckOut/ApplicantCheckOutPage.jsx";
import FaliureApplicant from "./Component/UsersDashboard/pages/Dashboard/WorkPermitVisa/CheckOut/FaliureApplicant.jsx";
import ApplicantPaymentSucces from "./Component/UsersDashboard/pages/Dashboard/WorkPermitVisa/CheckOut/applicantPaymentSucces.jsx";
import WorkPermitVisa from "./Component/UsersDashboard/pages/UserPage/WorkPermitVisa.jsx";

function App() {
  const ctx = useContext(VisaContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      ctx.setLogin(true);
    }
  }, [ctx]);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/About-Us" element={<AboutUs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/Privacy-Policy" element={<Privacy />} />
        <Route path="/Traveller-Blogs" element={<TravelerVlogs />} />
        <Route path="/Request-Reset-Password" element={<RequestResetPassword />} />
        <Route path="/Validate-Otp" element={<ValidateOTP />} />
        <Route path="/Reset-Password" element={<ResetPassword />} />
        <Route path="/Refund-Policy" element={<RefundPolicy />} />
        <Route path="/Term-And-Conditions" element={<TermAndConditions />} />
        <Route path="/otherCountris" element={<OtherCountris />} />        
        <Route path="/Freelancer" element={<Freelancer />} /> 
        <Route path="/Register-PaymentSuccess" element={<RegisterPaySuccess  />} /> 
        <Route path="/Register-PaymentFail" element={<RegisterFailure  />} /> 
        <Route path="*" element={<NoPage />} />

        {/* Private Routes */}
        {ctx.isLoggedIn && (
          <>          
            <Route path="/Vijatour/:id" element={<VisaApplyPage />} />
            <Route path="/Traveller-Detail" element={<TravelerDetails />} />
            <Route path="/Document" element={<Document />} />
            <Route path="/Checkout" element={<MainDetails />} />
            <Route path="/User-Dashboard" element={<Dashboard />} />
            <Route path="/User-Applications" element={<UserApplicatons />} />
            <Route path="/visasFiles" element={<VisasFiles />} />
            <Route path="/workPermitVisas" element={<WorkPermitVisa />} />           
            <Route path="/PaymentSuccess" element={<Success />} />
            <Route path="/PaymentFailed" element={<Failure />} />
            <Route path="/workPremitCards" element={<WorkCountryCards />} />
            <Route path="/ApplicantDetails" element={<WorkDetails />} />
            <Route path="/startApplication/:id" element={<StartApplication />} />
            <Route path="/applicantCheckOut" element={<ApplicantCheckOutPage />} />
            <Route path="/applicantSuccess" element={<ApplicantPaymentSucces />} />
            <Route path="/applicantFaliure" element={<FaliureApplicant />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;



