import React, { useContext, useEffect, useState } from "react";
import "./mainDetails.css";
import VisaContext from "../../../context/visa-context";
import Header from "../../Home/Header";
// // Strper
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import axios from "axios";

const MainDetails = () => {
  const loc = useLocation();
  const { state } = loc;
  const {
    travelers = [],
    country = {},
    futureDate,
    updatedAmount,
    countryPrice,
    addOnPrices,
    selectedDate,
  } = state || {};

  const Number = travelers.length;
  const Price =   Number > 0 
  ? (updatedAmount !== null ? updatedAmount / Number : countryPrice / Number) 
  : 0; 

 
  // State to store data to be posted
  const [postData, setPostData] = useState([]);
  const userId = localStorage.getItem("userId");
  const context = useContext(VisaContext);
  const travelerIds = JSON.parse(localStorage.getItem("travelerIds")) || [];

 
  useEffect(() => {
    // Date 📅 format 📅
    const formatDate = (date) => {
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    };
    // Time 📅 format 💯
    const now = new Date();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    // Date 📅 format 📅
    const data = travelers.map((traveler, index) => ({
      name: traveler.name,
      lname: traveler.lname,
      email: traveler.email,
      selectedDate: selectedDate
        ? formatDate(new Date(selectedDate))
        : formatDate(new Date()),
      futureDate: futureDate ? formatDate(new Date(futureDate)) : "",
      countryName: country.name,
      countryPrice: Price,
      addOnPrices: addOnPrices,
      userId: userId,
      travelerId: travelerIds[index], // Add traveler ID
      Time:formattedTime,
      status:"Processing"
    }));
    setPostData(data);
      // Save data in sessionStorage
      const Data = sessionStorage.getItem("postData", JSON.stringify(data));
      if(Data === Data){
        sessionStorage.removeItem("postData");
        sessionStorage.setItem("postData", JSON.stringify(data))
      }
  }, [
    travelers,
    selectedDate,
    futureDate,
    country.name,
    updatedAmount,
    addOnPrices,
  ]);

// PhonePay Payment gateway 💯 👍 💯 👍

const VisaPrice = updatedAmount !== null ? updatedAmount : countryPrice;
const data = {
  name: 'Abhay',
  amount: VisaPrice,
  number: '9999999999',
  userId:userId,
  MUID: "MUID" + Date.now(),
};

const handlePaymentGetway = async (e) => {
  e.preventDefault();
  try {
    const res = await axios.post('https://backend-visa2.vercel.app/api/payMent/order', { ...data });      

    if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {      
      window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
    }
  } catch (error) {
    if (error.response) {
      console.error('Response error details:', error.response.data);
    } else {
      console.error('Error in payment gateway:', error.message);
    }
  }
};
  
// PhonePay Payment gateway 💯 👍
useEffect(() => {
  window.scrollTo({ top: 0, behavior: "smooth" });
}, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Checkout</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="container stepper0144">
        <Stepper size="sm">
          <Step
            indicator={
              <StepIndicator
                variant="solid"
                sx={{ backgroundColor: "red", color: "white" }}
              >
                1
              </StepIndicator>
            }
          >
            Step 1
          </Step>
          <Step
            indicator={
              <StepIndicator
                variant="solid"
                sx={{ backgroundColor: "red", color: "white" }}
              >
                2
              </StepIndicator>
            }
          >
            Step 2
          </Step>
          <Step
            indicator={
              <StepIndicator
                variant="solid"
                sx={{ backgroundColor: "red", color: "white" }}
              >
                3
              </StepIndicator>
            }
          >
            Step 3
          </Step>
        </Stepper>
      </div>
      <div>
        <div className="addOnsFullPage">
          <div className="ReviewPayment">
            <form className="mainformAdd">
              <h2>Review Payment</h2>
              <div>
                {context.travellerData.map((Tdata, inde) => (
                  <div className="DummyFlightTicket">
                    <h5>
                      Name Of Traveller{" "}
                      <span
                        style={{
                          fontWeight: "500",
                          marginLeft: "5px",
                          color: "red",
                        }}
                      >
                        {inde + 1}
                      </span>{" "}
                    </h5>
                    <span className="DestinatioCont" key={inde}>
                      {Tdata.name} {Tdata.lname}{" "}
                    </span>
                  </div>
                ))}
              </div>
              <div className="DummyFlightTicket">
                <h5>Date Of Application</h5>
                <span>
                  {selectedDate ? (
                    <span className="DestinatioCont">
                      {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(selectedDate))}
                    </span>
                  ) : (
                    <span className="DestinatioCont">
                      {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date())}
                    </span>
                  )}
                </span>
              </div>
              <div className="DummyFlightTicket">
                <h5>Destination</h5>
                <span className="DestinatioCont">{country.name}</span>
              </div>
              <div className="DummyFlightTicket">
                <h5>Response Date By</h5>
                <span>
                  {futureDate ? (
                    <span className="DestinatioCont">
                      {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(futureDate))}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="DummyFlightTicket">
                <h5>Total Price</h5>
                <span className="DestinatioCont">
                  {" "}
                  {updatedAmount !== null ? updatedAmount : countryPrice}                
                </span>
              </div>
            </form>
            <div className="PaymentBtn">
              <button className="btn" onClick={handlePaymentGetway}>
                Pay Now
              </button>
            </div>
          </div>
        </div>      
      </div>
    </>
  );
};

export default MainDetails;
