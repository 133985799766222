function dateHelper(days) {
  let date = new Date();
  date.setDate(date.getDate() + days);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}
export const countries = [
  {
    id: 1,
    name: "Angola",
    pic: "/NewImage/angola_11zon.jpg",
    price: "7000",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
    ],
    flight: 110000,
    PriceBreakage: {
      AFees: "6000",
      SCharge: "1000",
    },
    hotel: 10000,
    general: 5000,
  },
  {
    id: 2,
    name: "Antigua and Barbuda",
    pic: "/NewImage/antigua-burbuda_11zon.jpg",
    price: "10800",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 150000,
    PriceBreakage: {
      AFees: "8500",
      SCharge: "2300",
    },
    hotel: 5000,
    general: 5000,
  },
  // {
  //   id: 3,
  //   name: "Argentina",
  //   pic: "/NewImage/argentina_11zon.jpg",
  //   price: "19000",
  //   date: dateHelper(31),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 31,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 150000,
  //   PriceBreakage: {
  //     AFees: "13000",
  //     SCharge: "6000",
  //   },
  //   hotel: 7000,
  //   general: 5000,
  // },
  {
    id: 4,
    name: "Armenia",
    pic: "/NewImage/armenia_11zon.jpg",
    price: "1600",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>be valid for at least 3 months after the intended date of departure.</li>
            <li>have at least 2 pages free from any visas/marks/stamps.</li>
            <li>be issued for no more than 10 years.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Physical Bank Statement  (6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Travel insurance",
        headContent: "",
        content: "",
      },
    ],
    flight: 100000,
    PriceBreakage: {
      AFees: "700",
      SCharge: "900",
    },
    hotel: 5000,
    general: 5000,
  },
  {
    id: 5,
    name: "Australia",
    pic: "/NewImage/australia_11zon.jpg",
    price: "14500",
    date: dateHelper(26),
    tag: "Get Visa  response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 26,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the Visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Physical Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Documents (If Salaried)",
        headContent: "",
        content: "",
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 100000,
    PriceBreakage: {
      AFees: "11000",
      SCharge: "3500",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 6,
    name: "Austria",
    pic: "/NewImage/austria_11zon.jpg",
    price: "4500",
    date: dateHelper(27),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 27,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 7000,
    general: 7500,
  },
  {
    id: 7,
    name: "Azerbaijan",
    pic: "/NewImage/azerbaijan_11zon.jpg",
    price: "2900",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 75000,
    PriceBreakage: {
      AFees: "2300",
      SCharge: "600",
    },
    hotel: 4000,
    general: 5000,
  },
  {
    id: 8,
    name: "Bahrain",
    pic: "/NewImage/bahrain_11zon.jpg",
    price: "3900",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the Visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },

      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "3000",
      SCharge: "900",
    },
    hotel: 7500,
    general: 5000,
  },
  // {
  //   id: 9,
  //   name: "Bangladesh",
  //   pic: "/NewImage/bangladesh_11zon.jpg",
  //   price: "3900",
  //   date: dateHelper(15),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 15,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 20000,
  //   PriceBreakage: {
  //     AFees: "3500",
  //     SCharge: "400",
  //   },
  //   hotel: 3000,
  //   general: 2500,
  // },
  // {
  //   id: Math.random(),
  //   name: "Barbados",
  //   pic: "/images/barbados.jpg",
  //   price: "     10000",
  //   date: new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Belarus",
  //   pic: "/images/belarus.jpg",
  //   price: "     10000",
  //   date: new Date()
  // },
  {
    id: 10,
    name: "Belgium",
    pic: "/NewImage/belgium_11zon.jpg",
    price: "4500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 80000,
    PriceBreakage: {
      para: "Fees include appointmen cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 12,
    name: "Benin",
    pic: "/NewImage/benin_11zon.jpg",
    price: "6800",
    date: dateHelper(9),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 9,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },

      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 70000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "2300",
    },
    hotel: 5000,
    general: 5000,
  },
  // {
  //   id: 13,
  //   name: "Bhutan",
  //   pic: "/NewImage/bhutan_11zon.jpg",
  //   price: "11200",
  //   date: dateHelper(8),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 8,
  //   docs: ["Photo", "Passport", "Hotel Bookings"],
  //   flight: 0,
  //   hotel: 0,
  //   general: 1200,
  // },
  // {
  //   id: 14,
  //   name: "Brazil",
  //   pic: "/NewImage/brazil_11zon.jpg",
  //   price: "10800",
  //   date: dateHelper(17),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 17,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 100000,
  //   PriceBreakage: {
  //     AFees: "8500",
  //     SCharge: "2300",
  //   },
  //   hotel: 7500,
  //   general: 5000,
  // },
  {
    id: 15,
    name: "Burkina Faso",
    pic: "/NewImage/burkina-faso_11zon.jpg",
    price: "8400",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 100000,
    PriceBreakage: {
      AFees: "6100",
      SCharge: "2300",
    },
    hotel: 7500,
    general: 2500,
  },
  {
    id: 16,
    name: "Cambodia",
    pic: "/NewImage/cambodia_11zon.jpg",
    price: "3900",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "3100",
      SCharge: "800",
    },
    hotel: 3000,
    general: 2500,
  },
  {
    id: 17,
    name: "Canada",
    pic: "/NewImage/canada_11zon.jpg",
    price: "17000",
    date: dateHelper(46),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 46,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },

      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Documents (If Salaried)",
        headContent: "",
        content: "",
      },
    ],

    flight: 100000,
    PriceBreakage: {
      AFees: "15000",
      SCharge: "2000",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 18,
    name: "Chile",
    pic: "/NewImage/chile_11zon.jpg",
    price: "15300",
    date: dateHelper(29),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 29,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 150000,
    PriceBreakage: {
      AFees: "12500",
      SCharge: "2800",
    },
    hotel: 7500,
    general: 5000,
  },
  // {
  //   id: 19,
  //   name: "China",
  //   pic: "/NewImage/china_11zon.jpg",
  //   price: "7500",
  //   date: dateHelper(9),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 9,

  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Balance Certificate",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 30000,
  //   PriceBreakage: {
  //     AFees: "4000",
  //     SCharge: "3500",
  //   },
  //   hotel: 3000,
  //   general: 2500,
  // },
  {
    id: 20,
    name: "Colombia",
    pic: "/NewImage/colombia_11zon.jpg",
    price: "14800",
    date: dateHelper(29),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 29,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Business Certificate",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Travel insurance",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
    ],
    flight: 180000,
    PriceBreakage: {
      AFees: "12000",
      SCharge: "2800",
    },
    hotel: 5000,
    general: 5000,
  },
  // {
  //   id: Math.random(),
  //   name: "Comoros",
  //   pic: "/images/comoros.jpg",
  //   price: "     10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Cook Island",
  //   pic: "/images/cook-island.jpg",
  //   price: "     10000",
  //   date:new Date()
  // },
  // {
  //   id: 21,
  //   name: "Costa Rica",
  //   pic: "/NewImage/costa-rica_11zon.jpg",
  //   price: "4800",
  //   date: dateHelper(24),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 24,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 55000,
  //   PriceBreakage: {
  //     AFees: "3000",
  //     SCharge: "1800",
  //   },
  //   hotel: 10000,
  //   general: 7000,
  // // },
  // {
  //   id: 22,
  //   name: "Cuba",
  //   pic: "/NewImage/cuba_11zon.jpg",
  //   price: "9500",
  //   date: dateHelper(23),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 23,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(6 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Affidavit",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 35000,
  //   PriceBreakage: {
  //     AFees: "7200",
  //     SCharge: "2300",
  //   },
  //   hotel: 4500,
  //   general: 5000,
  // },
  {
    id: 23,
    name: "Cyprus",
    pic: "/NewImage/cyprus_11zon.jpg",
    price: "4500",
    date: dateHelper(11),
    tag: "Get Visa response on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 11,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Travel Insurance",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },

      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "2500",
      SCharge: "2000",
    },
    hotel: 5500,
    general: 5000,
  },
  {
    id: 24,
    name: "Czechia",
    pic: "/NewImage/czechia_11zon.jpg",
    price: "4500",
    date: dateHelper(15),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 15,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 90000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 5000,
    general: 5000,
  },
  {
    id: 25,
    name: "Egypt",
    pic: "/NewImage/egypt_11zon.jpg",
    price: "4200",
    date: dateHelper(4),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 4,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "2700",
      SCharge: "1500",
    },
    hotel: 7500,
    general: 5000,
  },
  {
    id: 26,
    name: "Ethiopia",
    pic: "/NewImage/ehtiopia_11zon.jpg",
    price: "8300",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },

      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    PriceBreakage: {
      AFees: "7000",
      SCharge: "1300",
    },
    hotel: 4500,
    general: 4000,
  },
  // {
  //   id: Math.random(),
  //   name: "El Salvador",
  //   pic: "/images/el-salvador.jpg",
  //   price: "     10000",
  //   date:new Date()
  // },
  {
    id: 27,
    name: "Equitorial Guinea",
    pic: "/NewImage/equitorial-guinea_11zon.jpg",
    price: "8700",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      AFees: "6300",
      SCharge: "2400",
    },
    hotel: 5000,
    general: 4000,
  },
  {
    id: 28,
    name: "Estonia",
    pic: "/NewImage/estonia_11zon.jpg",
    price: "4500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 5000,
    general: 4000,
  },
  // {
  //   id: 29,
  //   name: "Fiji",
  //   pic: "/NewImage/fiji_11zon.jpg",
  //   tag: "Get Visa response on time or 100% return",
  //   price: "     10000",
  //   date: dateHelper(27),
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 27,
  //   docs: ["Photo", "Passport"],
  //   flight: 0,
  //   hotel: 0,
  //   general: 0,
  // },
  {
    id: 30,
    name: "Finland",
    pic: "/NewImage/finland_11zon.jpg",
    price: "4500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 31,
    name: "France",
    pic: "/NewImage/france_11zon.jpg",
    price: "4500",
    date: dateHelper(31),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 6000,
    general: 7500,
  },
  {
    id: 32,
    name: "Georgia",
    pic: "/NewImage/georgia_11zon.jpg",
    price: "5100",
    date: dateHelper(12),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 12,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "3100",
      SCharge: "2000",
    },
    hotel: 3500,
    general: 5000,
  },
  {
    id: 33,
    name: "Germany",
    pic: "/NewImage/germany_11zon.jpg",
    price: "4500",
    date: dateHelper(51),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 51,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 80000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  // {
  //   id: 34,
  //   name: "Ghana",
  //   pic: "/NewImage/ghana_11zon.jpg",
  //   price: "12500",
  //   date: dateHelper(24),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 24,

  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(6 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 80000,
  //   PriceBreakage: {
  //     AFees: "10300",
  //     SCharge: "2300",
  //   },
  //   hotel: 6500,
  //   general: 7000,
  // },
  {
    id: 35,
    name: "Greece",
    pic: "/NewImage/greece_11zon.jpg",
    price: "4500",
    date: dateHelper(4),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 4,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      para: "Fees include appoinment cost only",
    },
    hotel: 6000,
    general: 5000,
  },
  {
    id: 36,
    name: "Guinea",
    pic: "/NewImage/guinea_11zon.jpg",
    price: "8900",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 80000,
    PriceBreakage: {
      AFees: "6700",
      SCharge: "2200",
    },
    hotel: 6500,
    general: 5000,
  },
  // {
  //   id: 37,
  //   name: "Guyana",
  //   pic: "/NewImage/guyana_11zon.jpg",
  //   price: "1100",
  //   date: dateHelper(30),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 30,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: "Bank Statement(3 to 6 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Medical Report",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 250000,
  //   PriceBreakage: {
  //     AFees: "800",
  //     SCharge: "300",
  //   },
  //   hotel: 10000,
  //   general: 7500,
  // },
  {
    id: 38,
    name: "Hong Kong",
    pic: "/NewImage/hong-kong_11zon.jpg",
    price: "1700",
    date: dateHelper(1),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 1,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
    ],
    flight: 65000,
    PriceBreakage: {
      AFees: "400",
      SCharge: "1300",
    },
    hotel: 5500,
    general: 5000,
  },
  {
    id: 39,
    name: "Hungary",
    pic: "/NewImage/hungary_11zon.jpg",
    price: "4500",
    date: dateHelper(32),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 32,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 5500,
    general: 5000,
  },
  {
    id: 40,
    name: "Indonesia",
    pic: "/NewImage/indonesia_11zon.jpg",
    price: "3800",
    date: dateHelper(3),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      AFees: "3000",
      SCharge: "800",
    },
    hotel: 2500,
    general: 4000,
  },
  // {
  //   id: 41,
  //   name: "Israel",
  //   pic: "/NewImage/israel_11zon.jpg",
  //   price: "12800",
  //   date: dateHelper(32),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 32,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 55000,
  //   PriceBreakage: {
  //     AFees: "10500",
  //     SCharge: "2300",
  //   },
  //   hotel: 10000,
  //   general: 7500,
  // },
  {
    id: 42,
    name: "Italy",
    pic: "/NewImage/italy_11zon.jpg",
    price: "4500",
    date: dateHelper(33),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 33,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 8500,
    general: 7000,
  },
  {
    id: 43,
    name: "Japan",
    pic: "/NewImage/japan_11zon (1).jpg",
    price: "3000",
    date: dateHelper(25),
    tag: "Get Visa response on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 25,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
    ],
    flight: 44000,
    PriceBreakage: {
      AFees: "600",
      SCharge: "2400",
    },
    hotel: 6500,
    general: 5000,
  },
  {
    id: 44,
    name: "Jordan",
    pic: "/NewImage/jordan_11zon.jpg",
    price: "6200",
    date: dateHelper(2),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 2,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "5000",
      SCharge: "1200",
    },
    hotel: 4000,
    general: 3000,
  },
  {
    id: 45,
    name: "Kenya",
    pic: "/NewImage/kenya_11zon.jpg",
    price: "4800",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 50000,
    PriceBreakage: {
      AFees: "4300",
      SCharge: "500",
    },
    hotel: 7000,
    general: 5000,
  },
  {
    id: 46,
    name: "Kyrgyzstan",
    pic: "/NewImage/Kyrgyzstan_11Zom.jpg",
    price: "17300",
    date: dateHelper(16),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 16,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 75000,
    PriceBreakage: {
      AFees: "14600",
      SCharge: "2700",
    },
    hotel: 5000,
    general: 4000,
  },
  {
    id: 47,
    name: "Laos",
    pic: "/NewImage/laos_11zon.jpg",
    price: "4500",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 50000,
    PriceBreakage: {
      AFees: "4200",
      SCharge: "300",
    },
    hotel: 3000,
    general: 3000,
  },
  {
    id: 48,
    name: "Lebanon",
    pic: "/NewImage/lebanon_11zon.jpg",
    price: "10700",
    date: dateHelper(30),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 80000,
    PriceBreakage: {
      AFees: "8400",
      SCharge: "2300",
    },
    hotel: 9000,
    general: 7500,
  },
  {
    id: 49,
    name: "Liechtenstein",
    pic: "/NewImage/liechtenstein_11zon.jpg",
    price: "4500",
    date: dateHelper(21),
    tag: "Get Visa appointment  on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 21,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 12000,
    general: 7500,
  },
  {
    id: 50,
    name: "Madagascar",
    pic: "/NewImage/madagascar_11zon.jpg",
    price: "2800",
    date: dateHelper(3),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "2300",
      SCharge: "500",
    },
    hotel: 6000,
    general: 5000,
  },
  {
    id: 51,
    name: "Malaysia",
    pic: "/NewImage/malaysia_11zon.png",
    price: "250",
    date: dateHelper(3),
    tag: "Get Arrival Card on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 3,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 30000,
    PriceBreakage: {
      para: "Fees include mandatory arrival card",
    },
    hotel: 2500,
    general: 3000,
  },
  // {
  //   id: Math.random(),
  //   name: "Maldives",
  //   pic: "./images/maldives.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 52,
    name: "Malta",
    pic: "/NewImage/malta_11zon.jpg",
    price: "4500",
    date: dateHelper(6),
    tag: "Get Visa Appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 70000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 8000,
    general: 7000,
  },
  // {
  //   id: Math.random(),
  //   name: "Marshall Island",
  //   pic: "./images/marshall-island.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Mauritius",
  //   pic: "./images/mauritius.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Moldova",
  //   pic: "./images/moldova.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 53,
    name: "Mongolia",
    pic: "/NewImage/mongolia_11zon.jpg",
    price: "1450",
    date: dateHelper(4),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 4,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 90000,
    PriceBreakage: {
      AFees: "800",
      SCharge: "650",
    },
    hotel: 4000,
    general: 4000,
  },
  {
    id: 54,
    name: "Morocco",
    pic: "/NewImage/morocco_11zon.jpg",
    price: "7800",
    date: dateHelper(6),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 6,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 95000,
    PriceBreakage: {
      AFees: "6000",
      SCharge: "1800",
    },
    hotel: 8000,
    general: 6000,
  },
  {
    id: 55,
    name: "Mozambique",
    pic: "/NewImage/mozambique_11zon.jpg",
    price: "2950",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
    ],
    flight: 65000,
    PriceBreakage: {
      AFees: "14000",
      SCharge: "500",
    },
    hotel: 13000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Nepal",
  //   pic: "/images/nepal.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 56,
    name: "Netherlands",
    pic: "/NewImage/netherlands_11zon.jpg",
    price: "4500",
    date: dateHelper(30),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 8000,
    general: 7500,
  },
  {
    id: 57,
    name: "New Zealand",
    pic: "/NewImage/new-zealand_11zon.jpg",
    price: "16500",
    date: dateHelper(31),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 31,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Physical Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
      {
        heading: "Adhaar Card",
        headContent: "",
        content: "",
      },
      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },

      {
        heading: "Proof of Business",
        headContent: "",
        content: "",
      },
      {
        heading: "Retirement Letter(if Retired)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: " Student id card (If Student)",
        headContent: "",
        content: "",
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "11000",
      SCharge: "5500",
    },
    hotel: 7500,
    general: 6000,
  },
  {
    id: 58,
    name: "Norway",
    pic: "/NewImage/norway_11zon.jpg",
    price: "4500",
    date: dateHelper(21),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 21,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 15000,
    general: 7500,
  },
  {
    id: 59,
    name: "Oman",
    pic: "/NewImage/oman_11zon.jpg",
    price: "8000",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "PanCard",
        headContent: "",
        content: "",
      },
    ],
    flight: 35000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "3500",
    },
    hotel: 4000,
    general: 4000,
  },
  {
    id: 60,
    name: "Papua New Guinea",
    pic: "/NewImage/papua-guinea_11zon.jpg",
    price: "6500",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },

      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Medical Report",
        headContent: "",
        content: "",
      },

      {
        heading: " Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    flight: 250000,
    PriceBreakage: {
      AFees: "4200",
      SCharge: "2300",
    },
    hotel: 11000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Oleksandr P",
  //   pic: "/images/pexels-oleksandr-p-1002541.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: 95,
  //   name: "Philippines",
  //   pic: "/NewImage/philippines_11zon.jpg",
  //   price: "6500",
  //   date: dateHelper(29),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 29,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 25000,
  //   PriceBreakage: {
  //     AFees: "5000",
  //     SCharge: "1500",
  //   },
  //   hotel: 3500,
  //   general: 3000,
  // },
  {
    id: 61,
    name: "Poland",
    pic: "/NewImage/poland_11zon.jpg",
    price: "4500",
    date: dateHelper(8),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 6500,
    general: 5000,
  },
  {
    id: 62,
    name: "Qatar",
    pic: "/NewImage/qatar_11zon.jpg",
    price: "1950",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking from discover Qatar",
        headContent: "",
        content: "",
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "1200",
      SCharge: "750",
    },
    hotel: 11000,
    general: 7500,
  },
  {
    id: 63,
    name: "Russia",
    pic: "/NewImage/russia_11zon.jpg",
    price: "5200",
    date: dateHelper(9),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 9,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 85000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "700",
    },
    hotel: 3000,
    general: 2500,
  },
  {
    id: 64,
    name: "Rwanda",
    pic: "/NewImage/rwanda_11zon.jpg",
    price: "5300",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 60000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "800",
    },
    hotel: 6000,
    general: 5000,
  },
  {
    id: 65,
    name: "Umrah",
    pic: "/NewImage/Umrah.jpg",
    price: "12900",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "11500",
      SCharge: "1400",
    },
    hotel: 1500,
    general: 3000,
  },
  {
    id: 66,
    name: "India",
    pic: "/NewImage/India-temple.jpg",
    price: "10500",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 20000,
    PriceBreakage: {
      AFees: "9000",
      SCharge: "1500",
    },
    hotel: 3000,
    general: 2000,
  },
  {
    id: 68,
    name: "Saudi Arabia",
    pic: "/NewImage/saudi-arabia_11zon.jpg",
    price: "11800",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,

    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air Ticket",
        headContent: "",
        content: "",
      },

      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "ITR",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Itinerary",
        headContent: "",
        content: "",
      },
      {
        heading: "Medical Report",
        headContent: "",
        content: "",
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "10000",
      SCharge: "1800",
    },
    hotel: 5500,
    general: 4500,
  },
  // {
  //   id: Math.random(),
  //   name: "Senegal",
  //   pic: "./images/senegal.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Seychelles",
  //   pic: "/images/seychelles.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 69,
    name: "Singapore",
    pic: "/NewImage/singapore_11zon.jpg",
    price: "2600",
    date: dateHelper(10),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 10,
    docs: [
      {
        heading: "2 Photos",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Visa Application Form",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(6 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Leave Certificate (If Salaried)",
        headContent: "",
        content: "",
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Details (If Salaried)",
        headContent: "",
        content: "",
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: "",
      },
    ],

    flight: 30000,
    PriceBreakage: {
      AFees: "2400",
      SCharge: "200",
    },
    hotel: 6500,
    general: 5500,
  },
  {
    id: 70,
    name: "Slovakia",
    pic: "/NewImage/slovakia_11zon.jpg",
    price: "4500",
    date: dateHelper(21),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 21,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 65000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 4000,
    general: 3000,
  },
  {
    id: 71,
    name: "Slovenia",
    pic: "/NewImage/slovenia_11zon.jpg",
    price: "4500",
    date: dateHelper(23),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 23,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 75000,
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Somalia",
  //   pic: "./images/somalia.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: 72,
  //   name: "South Africa",
  //   pic: "/NewImage/south-africa_11zon.jpg",
  //   price: "7100",
  //   date: dateHelper(31),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 31,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },

  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 70000,
  //   PriceBreakage: {
  //     AFees: "4800",
  //     SCharge: "2300",
  //   },
  //   hotel: 4500,
  //   general: 3500,
  // },
  // {
  //   id: 73,
  //   name: "South Korea",
  //   pic: "/NewImage/south-korea_11zon.jpg",
  //   price: "7100",
  //   date: dateHelper(29),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 29,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 30000,
  //   PriceBreakage: {
  //     AFees: "3500",
  //     SCharge: "3600",
  //   },
  //   hotel: 3500,
  //   general: 3000,
  // },
  {
    id: 74,
    name: "Spain",
    pic: "/NewImage/spain_11zon.jpg",
    price: "5500",
    date: dateHelper(16),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 16,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include the appointment cost only",
    },
    hotel: 10000,
    general: 7500,
  },
  {
    id: 75,
    name: "Sri Lanka",
    pic: "/NewImage/srilanka_11zon.jpeg",
    price: "2900",
    date: dateHelper(2),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 2,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 10000,
    PriceBreakage: {
      AFees: "1600",
      SCharge: "1300",
    },
    hotel: 3500,
    general: 3000,
  },
  // {
  //   id: Math.random(),
  //   name: "Saint Kitts and Nevis",
  //   pic: "./images/st-kitts-nevis.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: Math.random(),
  //   name: "Saint Lucia",
  //   pic: "./images/st-lucia.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  {
    id: 76,
    name: "Sweden",
    pic: "/NewImage/sweden_11zon.jpg",
    price: "4500",
    date: dateHelper(15),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 15,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 45000,
    PriceBreakage: {
      para: "Fees include the appointment cost only",
      SCharge: "2000",
    },
    hotel: 8500,
    general: 7500,
  },
  {
    id: 77,
    name: "Switzerland",
    pic: "/NewImage/switzerland_11zon.jpg",
    price: "4500",
    date: dateHelper(27),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 27,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include the appointment cost only",
    },
    hotel: 12000,
    general: 7500,
  },
  {
    id: 78,
    name: "Taiwan",
    pic: "/NewImage/taiwan_11zon.jpg",
    price: "1450",
    date: dateHelper(5),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 5,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Flight Bookng",
        headContent: "",
        content: "",
      },
    ],
    flight: 35000,
    PriceBreakage: {
      AFees: "700",
      SCharge: "750",
    },
    hotel: 5000,
    general: 4000,
  },
  {
    id: 79,
    name: "Tajikistan",
    pic: "/NewImage/tajikistan_11zon.jpg",
    price: "6800",
    date: dateHelper(14),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 14,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "Flight Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
    ],
    flight: 55000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "2300",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 80,
    name: "Tanzania",
    pic: "/NewImage/tanzania_11zon.jpg",
    price: "5600",
    date: dateHelper(14),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 14,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 40000,
    PriceBreakage: {
      AFees: "4500",
      SCharge: "1100",
    },
    hotel: 10000,
    general: 7500,
  },
  // {
  //   id: Math.random(),
  //   name: "Thailand",
  //   pic: "./images/thailand.jpg",
  //   price: "10000",
  //   date:new Date()
  // },
  // {
  //   id: 81,
  //   name: "Turkiye",
  //   pic: "/NewImage/turkiye_11zon.jpg",
  //   price: "9300",
  //   date: dateHelper(20),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 20,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },

  //     {
  //       heading: "Employement Documents",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 35000,
  //   PriceBreakage: {
  //     AFees: "4300",
  //     SCharge: "5000",
  //   },
  //   hotel: 4000,
  //   general: 3500,
  // },
  // {
  //   id: 82,
  //   name: "UAE",
  //   pic: "/NewImage/UAE_11zon.jpg",
  //   price: "8400",
  //   date: dateHelper(3),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 3,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },

  //     {
  //       heading: "Pan Card",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 30000,
  //   PriceBreakage: {
  //     AFees: "6000",
  //     SCharge: "2400",
  //   },
  //   hotel: 4000,
  //   general: 4000,
  // },
  {
    id: 83,
    name: "United Kingdom",
    pic: "/NewImage/uk_11zon.jpg",
    price: "16500",
    date: dateHelper(40),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 40,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },

      {
        heading: "ITR (2 year)",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
      {
        heading: "Business Proof (If Self-Employed)",
        headContent: "",
        content: "",
      },
      {
        heading: "Employment Documents (If Salaried)",
        headContent: "",
        content: "",
      },
      {
        heading: "Student Id-Card (If Student)",
        headContent: "",
        content: "",
      },
      {
        heading: "Salary slip/Leave Certificate(If Employed)",
        headContent: "",
        content: "",
      },
    ],
    flight: 80000,
    PriceBreakage: {
      para: "Fees include all charges at every step of Visa application",
    },
    hotel: 8000,
    general: 7500,
  },
  // {
  //   id: 84,
  //   name: "Uruguay",
  //   pic: "/NewImage/uruguay_11zon.jpg",
  //   price: "5200",
  //   date: dateHelper(24),
  //   tag: "Get Visa response on time or free",
  //   notice: "Public Holidays and weekends are considered as non working days.",
  //   days: 24,
  //   docs: [
  //     {
  //       heading: "Photo",
  //       headContent:
  //         "The photographs must be provided as per the given photo specifications",
  //       content: `
  //       <ul>
  //         <li>It must cover face 80% of the frame.</li>
  //         <li>It must be clicked on an all white background.</li>
  //         <li>Two copies to be submitted with general photo guidelines.</li>
  //         <li>It must have a matte finish.</li>
  //         <li>It must be a recent photograph.</li>
  //         <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
  //         <li>Any kind of glasses must not be worn in the photograph.</li>
  //         <li>The hair must be tied back so that the face is clearly visible.</li>
  //         <li>The ears must be visible clearly.</li>
  //         <li>It must not have any headgear worn apart from religious reasons.</li>
  //         <li>It is to be taken care that your teeth do not show in the photograph.</li>
  //       </ul>
  //     `,
  //     },
  //     {
  //       heading: "Passport",
  //       headContent:
  //         "The submitted passport must meet the parameters given below",
  //       content: `
  //         <ul>
  //           <li>It must have minimum two blank pages for the visa stamp.</li>
  //           <li>It must be original and genuine.</li>
  //           <li>It must be valid for 6 months ahead of the span of stay.</li>
  //           <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
  //         </ul>
  //       `,
  //     },
  //     {
  //       heading: "Air-Ticket",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Yellow fever Vaccination",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "ITR (2 year)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Bank Statement(3 Month)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Hotel Booking",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Itinerary",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Visa Form",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "PanCard",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Adhaar Card",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Cover Letter",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Salary slip/Leave Certificate(If Employed)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Proof of Business",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: "Retirement Letter(if Retired)",
  //       headContent: "",
  //       content: "",
  //     },
  //     {
  //       heading: " Student id card (If Student)",
  //       headContent: "",
  //       content: "",
  //     },
  //   ],
  //   flight: 160000,
  //   PriceBreakage: {
  //     para: "Fees include all charges at every step of Visa application",
  //   },
  //   hotel: 8500,
  //   general: 7500,
  // },
  {
    id: 85,
    name: "United States",
    pic: "/NewImage/us_11zon.jpg",
    price: "28000",
    date: dateHelper(70),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 70,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    flight: 100000,
    PriceBreakage: {
      para: "Fees include all charges at every step of Visa application",
    },
    hotel: 15000,
    general: 7500,
  },
  {
    id: 86,
    name: "Uzbekistan",
    pic: "/NewImage/uzbekistan_11zon.jpg",
    price: "2700",
    date: dateHelper(7),
    tag: "Get Visa response on time or free ",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow fever Vaccination",
        headContent: "",
        content: "",
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "2000",
      SCharge: "700",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 87,
    name: "Vietnam",
    pic: "/NewImage/vietnam_11zon.jpg",
    price: "2850",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
    ],
    flight: 25000,
    PriceBreakage: {
      AFees: "2400",
      SCharge: "450",
    },
    hotel: 3500,
    general: 3000,
  },
  {
    id: 88,
    name: "Zambia",
    pic: "/NewImage/Zambia.jpg",
    price: "5800",
    date: dateHelper(12),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 12,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Return Air Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
    ],
    PriceBreakage: {
      AFees: "4300",
      SCharge: "1500",
    },
  },
  {
    id: 89,
    name: "Uganda",
    pic: "/NewImage/Uganda.jpg",
    price: "8800",
    date: dateHelper(8),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 8,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Return Air Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Cover Letter",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow Fever Certificate",
        headContent: "",
        content: "",
      },
    ],
    PriceBreakage: {
      AFees: "8400",
      SCharge: "400",
    },
  },
  {
    id: 90,
    name: "Togo",
    pic: "/NewImage/Togo.webp",
    price: "3500",
    date: dateHelper(11),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 11,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    PriceBreakage: {
      AFees: "2100",
      SCharge: "1400",
    },
  },
  {
    id: 91,
    name: "South Sudan",
    pic: "/NewImage/sudan.jpg",
    price: "11300",
    date: dateHelper(7),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 7,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Yellow Fever Certificate",
        headContent: "",
        content: "",
      },
    ],
    PriceBreakage: {
      AFees: "9800",
      SCharge: "1500",
    },
  },
  {
    id: 92,
    name: "Romania",
    pic: "/NewImage/Romania.avif",
    price: "4500",
    date: dateHelper(30),
    tag: "Get Visa appointment on time or 100% refund",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
    ],
    PriceBreakage: {
      para: "Fees include appointment cost only",
    },
  },
  {
    id: 93,
    name: "Kuwait",
    pic: "/NewImage/Kuwait-City.webp",
    price: "7500",
    date: dateHelper(30),
    tag: "Get Visa response on time or free",
    notice: "Public Holidays and weekends are considered as non working days.",
    days: 30,
    docs: [
      {
        heading: "Photo",
        headContent:
          "The photographs must be provided as per the given photo specifications",
        content: `
        <ul>
          <li>It must cover face 80% of the frame.</li>
          <li>It must be clicked on an all white background.</li>
          <li>Two copies to be submitted with general photo guidelines.</li>
          <li>It must have a matte finish.</li>
          <li>It must be a recent photograph.</li>
          <li>The expressions on the face must be neutral, there must not be any kind of smiling or frowning in the photograph.</li>
          <li>Any kind of glasses must not be worn in the photograph.</li>
          <li>The hair must be tied back so that the face is clearly visible.</li>
          <li>The ears must be visible clearly.</li>
          <li>It must not have any headgear worn apart from religious reasons.</li>
          <li>It is to be taken care that your teeth do not show in the photograph.</li>
        </ul>
      `,
      },
      {
        heading: "Passport",
        headContent:
          "The submitted passport must meet the parameters given below",
        content: `
          <ul>
            <li>It must have minimum two blank pages for the visa stamp.</li>
            <li>It must be original and genuine.</li>
            <li>It must be valid for 6 months ahead of the span of stay.</li>
            <li>It must not be torn, ripped, split, or damaged in any kind of way.</li>
          </ul>
        `,
      },
      {
        heading: "Visa Form",
        headContent: "",
        content: "",
      },
      {
        heading: "Air-Ticket",
        headContent: "",
        content: "",
      },
      {
        heading: "Hotel Booking",
        headContent: "",
        content: "",
      },
      {
        heading: "Bank Statement(3 Month)",
        headContent: "",
        content: "",
      },
    ],
    // flight: 25000,
    // hotel: 3500,
    // general: 3000,
  },
];
