export const WorkPermitCountries = [
  {
    id: 1,
    name: "Dubai",
    pic: "/NewImage/UAE-workPermit.jpeg",
    price: "160000",
    FirstFees: "70000",
    days: 14,
    jobCards: [
      {
        id: 1,
        Country: "Dubai",
        Job: "Packaging Worker",
        Salary: "2000 AED",
        FirstFees: "70000",
        Working: "Monday to Saturday",
        Accomodation: "Provided by Company",
        Transportation: "Provided by Company",
        Insurance: "Provided by Company",
        VisaFee: "Included",
        FlightT: "Included",
        PTime: "14 days",
        SC: "160000",
        Interview: "Not Required",
        Contract: "2 Year",
        Document: " Photo, Passport",
      },
    ],
  },
  {
    id: 2,
    name: "Saudi Arabia",
    pic: "/NewImage/Saudi-Arabia-workPermit.jpg",
    price: "180000",
    FirstFees: "70000",
    days: 15,
    jobCards: [
      {
        id: 2,
        Country: "Saudi Arabia",
        Job: "Delivery Bike Riders",
        Salary: "1950 SR",
        FirstFees: "70000",
        Eligibility: "Must know how use maps on phone & basic English",
        Accomodation: "Provided by Company",
        Transportation: "Provided by Company",
        Insurance: "Provided by Company",
        VisaFee: "Included",
        Working: "Monday to Saturday",
        FlightT: "Included",
        PTime: "15 days",
        SC: "180000",
        Interview: "Not Required",
        Contract: "2 Year",
        Document: " Photo, Passport, Medical Report, Resume",
      },
    ],
  },
  {
    id: 3,
    name: "Slovenia",
    pic: "/NewImage/slovenia_workVisa.jpg",
    price: "600000",
    FirstFees: "50000",
    days: 120,
    jobCards: [
      {
        id: 3,
        Country: "Slovenia",
        Job: "Gardener",
        Salary: "750 EUR - 850 EUR ",
        FirstFees: "50000",
        Eligibility:
          "Good eyesight and should be able to speak and understand English Language",
        Accomodation: "Provided by Company",
        Food: "Provided by Company",
        Transportation: "Provided by Company",
        Insurance: "Provided by Company",
        VisaFee: "Included",
        FlightT: "Included",
        PTime: "4-6 months",
        SC: "600000",
        Interview: "Required",
        Contract: "2+ Year",
        Document: " PCC,Photo, Passport, Medical Report, Resume",
        JD: `
          <ul>
                        <li>Preparing seedlings for delivery on-site</li>
                        <li>Loading and unloading seedlings</li>
                        <li>Performing gardening tasks</li>
                        <li>Assisting with work in the greenhouse</li>
                        <li>Planting seedlings</li>
                        <li>Transplanting seedlings</li>
                        <li>Preparing materials (pots, trays) for planting and attaching labels</li>
                        <li>Pricking out seedlings</li>
                      </ul>`,
      },
    ],
  },
];
