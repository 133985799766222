import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import "./visaApply.css";
import Header from "../Home/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { countries } from "../countries";
import CardMedia from "@mui/material/CardMedia";
import {  FaRupeeSign } from "react-icons/fa";
import { MdOutlineVerifiedUser } from "react-icons/md";
import Form from "react-bootstrap/Form";
import Footer from "../Footer/Footer.jsx";
import VisaCalcultor from "./VisaCalculator.jsx";
import { Helmet } from "react-helmet";

  // Define the array of oppintment Date countries 👍
  const appointmentCountries = [
    'Spain', 'France', 'Poland', 'Austria','United States',"Romania", 
    'Germany', 'Italy', 'Malta', 'Belgium', 
    'Czechia', 'Estonia', 'Finland',"Greece","Hungary","Liechtenstein","Netherlands","Norway","Slovakia","Slovenia","Sweden",
    "Switzerland"
  ];


const VisaApplyPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [futureDate, setFutureDate] = useState(null);
  const [country, setCountry] = useState(null);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const selectedCountry = countries.find(
      (country) => country.id === parseFloat(id)
    );
    setCountry(selectedCountry);
    if (selectedCountry) {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + selectedCountry.days);
      setFutureDate(futureDate);
    }
    // Scroll to the top of the page when the component mounts
  }, [id]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const futureDate = new Date(date);
    futureDate.setDate(futureDate.getDate() + country.days);
    setFutureDate(futureDate);
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleStartApplication = () => { 
      navigate("/Traveller-Detail", {
        state: { country, futureDate, selectedDate },
      });   
    
  };



  // Compute the display text after the country has been set
  const displayText = country
    ? appointmentCountries.includes(country.name)
      ? 'Appointment date'
      : 'Visa response date'
    : '';

    const displayNewText = country
    ? appointmentCountries.includes(country.name)
      ? <>Appointment and <br /> Travel Insurance</>
      : 'Visa'
    : '';

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;

  return (
    <>
    
      <div className="ViaApply_Header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{country?country.name:"Start-Application"}</title>
          <meta
          name="description"
          content="Need a visa? Apply for your visa with ease. Start your journey today!"
        />
        <meta
          name="keywords"
          content="work visa requirements, visa requirements by country, how to apply for a visa, tourist visa, work visa, student visa, and interview preparation.,"
        />
       <meta property="og:url" content={currentUrl} />
       <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        </Helmet>
      </div>
      {country && (
        <div className="main-wrapper-visaPage">
          <div className="CountryName">{country.name}</div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="collumPadding">
                  <div className="card visaCard1">
                    <div className="card-img">
                      <h3 className="img-content">{country.tag}</h3>
                      <CardMedia
                        image={process.env.PUBLIC_URL + country.pic}
                        component="img"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  <div className="Accordian_Box">
                    <div className="visaCard_content">
                      <p>
                        <span>Notice:</span> {country.notice}
                      </p>
                      <h4>Documents required</h4>
                    </div>
                    {country.docs.map((document, index) => (
                      <div className="accordian11" key={index}>
                        <Accordion defaultActiveKey={["0"]}>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              {document.heading}
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>{document.headContent}</p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: document.content,
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="collumPadding">
                  <div className="heading55">
                    <h5>Please Select a Date</h5>
                    <hr className="border_bottom" />
                  </div>
                  <div className="datePIcker">                   
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      inline
                      minDate={new Date()}
                    />
                  </div>
                  <hr className="border_top" />
                  <div className="slectDate001">
                    <h4>Your Selected Date</h4>
                    <h6>
                      {selectedDate ? (
                        <span className="placeholder-text">
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          }).format(selectedDate)}
                        </span>
                      ) : (
                        <span className="placeholder-text11">
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          }).format(new Date())}
                        </span>
                      )}
                    </h6>
                  </div>
                  <hr className="border_bottom" />

                  <div className="slectDate25">
                    <h4>{displayText}</h4>
                    <h6>
                      <span className="placeholder-text">
                        {futureDate
                          ? new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            }).format(futureDate)
                          : ""}
                      </span>
                    </h6>
                  </div>

                  <hr className="border_top" />
                  <div className="incree-decreeButton">
                    <h4 className="quantity_text mr-5">Number Of Travellers</h4>
                    <span className="quantity-picker">
                      <button onClick={handleDecrement}>-</button>
                      <span>{quantity}</span>
                      <button onClick={handleIncrement}>+</button>
                    </span>
                  </div>

                  <hr className="border_top" />
                  <div className="visa_colorBox">
                    <div className="cost122">
                      <h5>Your {displayNewText}  in just </h5>
                      <h5>
                        <FaRupeeSign /> {country.price * quantity}
                      </h5>
                    </div>
                    <hr className="border_bottom01" />
                    <div>
                      <ul>
                        <li style={{ marginBottom: "3px" }}>
                          Completely Free Assistance For Documents Required
                        </li>
                        <li>24*7 Chat Support</li>
                        <li> 5 Minutes Application Process</li>
                      </ul>
                    </div>
                  </div>
                  <hr className="border_bottom" />
                  <div className="slectDate25">
                    <h4>
                      <MdOutlineVerifiedUser /> Bank Balance Calculator{" "}
                    </h4>
                    <div className="checkTotalAmount">
                      <Form.Check
                        aria-label="option 1"
                        onClick={() => setCheck(!check)}
                      />
                      <label htmlFor="#" className="labeCheck">
                        Ideal Bank Balance At The Time Of Filing Visas
                      </label>
                    </div>
                  </div>
                  <div className="visacalc">{check && <VisaCalcultor />}</div>

                  <div className="text-center">
                    <button
                      className="btn btn-primary btn_primarybtn"
                      onClick={handleStartApplication}
                    >
                      Start Application
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default VisaApplyPage;
