import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../mainDetails.css";
import Button from "@mui/material/Button";

const Success = () => {
  const [postData, setPostData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = sessionStorage.getItem("postData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (Array.isArray(parsedData) && parsedData.length > 0) {
        setPostData(parsedData);
      }
    }
  }, []);
  
  useEffect(() => {
    if (Array.isArray(postData) && postData.length > 0) {
      const item = postData[0];
      if (isFormDataValid(postData)) {
        saveApplicationData();
      } else {
        console.log("Data is invalid or missing. Not calling saveApplicationData.");
      }
    } else {
      console.log("postData is not an array or is empty.");
    }
  }, [postData]);
  

  const isFormDataValid = (formData) => {
    if (Array.isArray(formData) && formData.length > 0) {
      const item = formData[0]; // Access the first item in the array
      return (
        item.name &&
        item.lname &&
        item.email &&
        item.selectedDate &&
        item.futureDate &&
        item.status &&
        item.travelerId &&
        item.userId &&
        item.countryPrice &&
        item.countryName &&
        item.Time &&
        item.addOnPrices &&
        typeof item.addOnPrices === 'object' &&
        Object.keys(item.addOnPrices).length > 0
      );
    }
    return false;
  };

  const saveApplicationData = async () => {
    if (!postData || !Array.isArray(postData) || postData.length === 0 ) {
      console.error("Post data is empty or undefined");
      return;
    }
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/userHistoryData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData), // Ensure postData is defined and structured correctly
        }
      )
      if (!response.ok) {
        throw new Error(`HTTP error! status frontend: ${response.status}`);
      }
      // Remove postData from sessionStorage
      console.log("Data saved successfully!");
      sessionStorage.removeItem("postData");
      const responseData = await response.json();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleNext = () => {
    navigate('/');
   
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="failure_page55">
        <div className="payment-success-img">
          <img src="/NewImage/payment-successful.webp" alt="payment-image" />
        </div>
        <div className="centered-element">
          <div className="otp_verify">
            <h2>Payment Successfull!</h2>
          </div>
          <Button
            variant="contained"
            size="small"
            className="buton_payment"
            onClick={handleNext}
          >
            OK
          </Button>
        </div>
      </div>
    </>
  );
};

export default Success;
