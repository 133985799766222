import React, { useEffect, useState } from "react";
import VisaContext from "./visa-context";

const VisaContextProvider = ({ children }) => {
  const [visasFiles,setVisasFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [traveller, setTraveller] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [applications,setApplications] = useState([]);
  const [users, setUsers] = useState([])
  const [workVisas, setWorkVisas] = useState([]);
  const setLoading = (value) => {
    setIsLoading(value);
  };
  const setLogin = (value) => {
    setIsLoggedIn(value);
  };


  const setTravellerData = async (data) => {
    setLoading(true);
    const response = await fetch("https://backend-visa2.vercel.app/api/getTraveller", {
      method: "POST",
      body: JSON.stringify({ id: data.id }),
      headers: {
        "content-type": "application/json",
      },
    });
    const datas = await response.json();
    if (datas) setTraveller(datas[0].data);
    setLoading(false);
  };
  
 
  const photoUpdater = (comingPhoto) => {
    setTraveller((prev) => {
      const existingData = [...prev];
      const photoArray = existingData[comingPhoto.id].photo;
  
      // Check if the photo already exists
      const photoExists = photoArray.some(
        (photo) =>
          photo.filename === comingPhoto.filename &&
          photo.originalname === comingPhoto.originalname
      );
  
      if (!photoExists) {
        photoArray.push({
          filename: comingPhoto.filename,
          originalname: comingPhoto.originalname,
        });
      }
  
      return existingData;
    });
  };
  
  const passportUpdater = (comingPhoto) => {
    setTraveller((prev) => {
      const existingData = [...prev];
      const passportArray = existingData[comingPhoto.id].passport;
  
      // Check if the passport file already exists
      const passportExists = passportArray.some(
        (passport) =>
          passport.filename === comingPhoto.filename &&
          passport.originalname === comingPhoto.originalname
      );
  
      if (!passportExists) {
        passportArray.push({
          filename: comingPhoto.filename,
          originalname: comingPhoto.originalname,
        });
      }
  
      return existingData;
    });
  };
  
  const OtherUpdater = (comingPhoto) => {
    setTraveller((prev) => {
      const existingData = [...prev];
      const otherDocumentsArray = existingData[comingPhoto.id].otherDocuments;
  
      // Check if the other document already exists
      const otherExists = otherDocumentsArray.some(
        (doc) =>
          doc.filename === comingPhoto.filename &&
          doc.originalname === comingPhoto.originalname
      );
  
      if (!otherExists) {
        otherDocumentsArray.push({
          filename: comingPhoto.filename,
          originalname: comingPhoto.originalname,
        });
      }
  
      return existingData;
    });
  };

  const handlePassportDelete = (name) => {
    setTraveller((prev) => {
      const existingData = [...prev];
      const p = existingData.find((item) => {
        return item.passport.find((i) => {
          return i.originalname === name;
        });
      });
  
      if (!p) {
        return existingData; // If no matching item is found, return existing data without changes
      }
  
      const indexP = existingData.findIndex((ff) => {
        return ff === p;
      });
      const ss = p.passport.find((i) => {
        return i.originalname === name;
      });
  
      const filteredData = p.passport.filter((a) => {
        return a !== ss;
      });
      p.passport = filteredData;
      existingData[indexP] = p;
      return existingData;
    });
  };
  
  const handlePhotoDelete = (name) => {
    setTraveller((prev) => {
      const existingData = [...prev];
      const p = existingData.find((item) => {
        return item.photo.find((i) => {
          return i.originalname === name;
        });
      });
  
      if (!p) {
        return existingData; // If no matching item is found, return existing data without changes
      }
  
      const indexP = existingData.findIndex((ff) => {
        return ff === p;
      });
      const ss = p.photo.find((i) => {
        return i.originalname === name;
      });
  
      const filteredData = p.photo.filter((a) => {
        return a !== ss;
      });
      p.photo = filteredData;
      existingData[indexP] = p;
      return existingData;
    });
  };
  
  const handleOtherDelete = (name) => {
    setTraveller((prev) => {
      const existingData = [...prev];
      const p = existingData.find((item) => {
        return item.otherDocuments.find((i) => {
          return i.originalname === name;
        });
      });
  
      if (!p) {
        return existingData; // If no matching item is found, return existing data without changes
      }
  
      const indexP = existingData.findIndex((ff) => {
        return ff === p;
      });
      const ss = p.otherDocuments.find((i) => {
        return i.originalname === name;
      });
  
      const filteredData = p.otherDocuments.filter((a) => {
        return a !== ss;
      });
      p.otherDocuments = filteredData;
      existingData[indexP] = p;
      return existingData;
    });
  };
  
  
  
   // FETCH APPLICATIONS 👍💯 👍

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/userHistoryData-ForUser",
        {
          headers: {
            "content-type": "application/json",
            'Authorization': 'Bearer token'

          },
        }
      );
      if (!response.ok) {
        setLoading(false);
        throw new Error(
          `Error fetching applications: ${response.statusText}`
        );
      }
      const data = await response.json();
      setLoading(false);

      if (Array.isArray(data.data)) {
        setLoading(false);
        setApplications(data.data.reverse()); // Reverse to show latest applications first
      } else {
        console.error("Unexpected data format:", data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
      setLoading(false);
    } 
  };
  useEffect(() => {
    fetchApplications(); // Fetch users when the component mounts
  }, []); // Empty dependency array ensures this runs once

   // FETCH Register users 👍💯 👍
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://backend-visa2.vercel.app/api/get-registerUsers");
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }
        setLoading(false);
        const data = await response.json();
        if (Array.isArray(data)) {
          const reversedData = data.reverse();
          setUsers(reversedData); // Reverse to show latest applications first
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

    //******************* */ get visas files*************** 👍
     // Users
     const userId = localStorage.getItem("userId");
     const RegisterUsers = users.filter((app) => {
      return app._id === userId;
    });
    const email = RegisterUsers.length > 0 ? RegisterUsers[0].email : "";

    useEffect(() => {
      const getVisaByEmail = async (email) => {
        try {
          const response = await fetch(`https://backend-visa2.vercel.app/api/getVisaFiles/${email}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setVisasFiles(data.data || []); // Set the data part of the response
        } catch (error) {
          console.error('Error fetching visa data:', error);
        }
      };
  
      if (email) { // Ensure email is not empty before making the request
        getVisaByEmail(email);
      }
    }, [email]); // Run effect when email changes



    useEffect(() => {
      const userId = localStorage.getItem("userId")
      console.log("userId",userId)
      // Only fetch data if id is available
      if (userId) {
        const fetchWorkApplication = async () => {
          try {
            const response = await fetch(
              `https://backend-visa2.vercel.app/api/get-workApplications?userId=${userId}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            const data = await response.json();
            console.log(data); // Process the response data
            setWorkVisas(data.data.data);
          } catch (error) {
            console.error("Error fetching work data:", error);
          }
        };
        fetchWorkApplication();
      }
    }, []); // Add id as a dependency


  const ctxValue = {
    setTravellerData,
    travellerData: traveller,
    photoUpdater,
    passportUpdater,
    OtherUpdater,
    handlePassportDelete,
    handleOtherDelete,
    handlePhotoDelete,
    isLoggedIn,
    setLogin,
    isLoading,
    setLoading,
    fetchApplications,
    applications,
    users,
    visasFiles,
    workVisas,
  };
  return (
    <VisaContext.Provider value={ctxValue}>{children}</VisaContext.Provider>
  );
};
export default VisaContextProvider;
