import React, { useContext, useEffect } from "react";
import "../../userDashboard.css";
import {
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import Header from "../../../Home/Header";
import VisaContext from "../../../../context/visa-context";
import { GoFileSubmodule } from "react-icons/go";
import { FaCcVisa } from "react-icons/fa6";
import { FaMagnifyingGlassArrowRight } from "react-icons/fa6";

const Dashboard = () => {

  const userId = localStorage.getItem("userId");
  const ctxApi = useContext(VisaContext);
  // const Users = ctxApi.userDetail;
  const application = ctxApi.applications;
  const visasFiles = ctxApi.visasFiles;
  const ctxData = useContext(VisaContext)


  // ***************Filter applications based on userId and get the count ***********👍
  const userApplications = application.filter(
    (app) => app.postData.userId === userId
  );
  const applicationCount = userApplications.length;

// *************👍*********
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // *************👍*********
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="container">
        <div className="main-container">
          <div className="main-title">
            <h3>DASHBOARD</h3>
          </div>

          <div className="main-cards">
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Tourist Applications</h3>
                <Link
                  to={"/User-Applications"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <BsFillGrid3X3GapFill className=" card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}> {applicationCount} </h1>
            </div>
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Work Permit Visa</h3>
                <Link
                  to={"/workPremitCards"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <FaMagnifyingGlassArrowRight className="card_dashboard_icon" />
                </Link>
              </div>
              <h1> </h1>
            </div>
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Visa Files</h3>
                <Link
                  to={"/visasFiles"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <GoFileSubmodule className="card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}> {visasFiles.length} </h1>
            </div>
            <div className="card_dashboard">
              <div className="card_dashboard-inner">
                <h3 style={{ color: "white" }}>Work Permit Applications</h3>
                <Link
                  to={"/workPermitVisas"}
                  style={{
                    listStyle: "none",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  <FaCcVisa className="card_dashboard_icon" />
                </Link>
              </div>
              <h1 style={{ color: "white" }}> {ctxData.workVisas.length} </h1>
            </div>
          </div>
        </div>
      </div>     
    </>
  );
};

export default Dashboard;
