import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import Select from "react-select";
import BackgroundImage from "../images/background-CXhjqumm.jpg";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../countries";
import VisaContext from "../../context/visa-context";

const Header = () => {
  const ctx = useContext(VisaContext);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const Usres = ctx.users;

  //  *********show user name  👍*****************
  const RegisterUsers = Usres.filter((app) => {
    return app._id === userId;
  });


  const RegisterUsersName = RegisterUsers.map((name, ind) => {
    return name.name;
  });
  //  *********show user name  👍*****************

  useEffect(() => {
    const countryOptions = countries.map((country) => ({
      label: country.name,
      value: country.id,
    }));
    setCountryList(countryOptions);
  }, []);

  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
    navigate(`/Vijatour/${selectedOption.value}`);
  };

  return (
    <div className="Header">  
      <div className="nav">
        <h1>
          <Link to={"/"} style={{ color: "white", marginTop: "7px" }}>
            Vijatour
          </Link>
        </h1>
        {!ctx.isLoggedIn && (          
          <>          
          <button
            className="login-btn"
            onClick={() => {
              navigate('/register')
            }}
          >
            Register
          </button>
          </>
        )}
        {!ctx.isLoggedIn && (          
          <>          
          <button
            className="login-btn"
            onClick={() => {
              navigate('/login')
            }}
          >
            login
          </button>
          </>
        )}
        <div className="logoutBtn">
          {ctx.isLoggedIn && (
            <>
            <span className="usreName_name">{RegisterUsersName}</span>
              <button
                className="login-btn"
                onClick={() => {
                  ctx.setLogin(false);
                  localStorage.removeItem("token");
                  navigate("/");
                }}
              >
                logout
              </button>
            </>
          )}
          {ctx.isLoggedIn && (
            <button
              className="login-btn"
              onClick={() => {
                navigate("/User-Dashboard");
              }}
            >
              Dashboard
            </button>
          )}
        </div>
      </div>
      <div className="main-content">
        <h1
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "2.85rem",
            margin: "8px 0px",
          }}
        >
          Get Your Visa Easily with Us
        </h1>
        <div className="buttons">
          <div className="dropdown-btn">
            <Select
              options={countryList}
              value={selectedCountry}
              onChange={handleCountrySelect}
              placeholder={"Select Your Destination"}
            />
          </div>
        </div>
      </div>
      <div className="image-section">
        <h2
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
            margin: "30px 0px 8px 0px",
          }}
        >
          Get your tourist Visa processed faster and easier by exploring our
          Visa Platform
        </h2>
        <div className="header_Img">
          <img
            loading="lazy"
            src={BackgroundImage}
            alt="Header image for Vijatour.com"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
