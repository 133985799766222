import React, { useEffect, useState } from "react";
import "./register.css";
import { Link} from "react-router-dom";
import { validateFormRegister } from "../../../util/validation";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import Button from "@mui/material/Button";

const Register = () => {
  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState("");
  const [RegformData, setRegformData] = useState({
    name: "",
    lname: "",
    email: "",
    Cname: "",
    password: "",
    Cnomber: "",
  });

  const [activeKey, setActiveKey] = useState("0");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegformData({ ...RegformData, [name]: value });
  };

    // Handle OTP input change
    const handleOtpChange = (e) => {
      setOtp(e.target.value);
      if (otpError) {
        setOtpError(""); // Clear the error message if OTP is entered
      }
    };

  // handle next
  const handleNext = (e) => {
    e.preventDefault();
    const newErrors = validateFormRegister(RegformData);
    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    if (isValid) {
      setActiveKey("1");
    }
    return isValid;
  };

  // ******************Payment for Register********************* 💯
  const data = {
    amount: 299,
  };

  const handlePaymentGetway = async (e) => {
    e.preventDefault();
    // Save form data to localStorage before payment
    sessionStorage.setItem("RegformData", JSON.stringify(RegformData));

    try {
      const res = await axios.post(
        "https://backend-visa2.vercel.app/api/payMent/initiate-payment",
        { ...data }
      );

      if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (error) {
      if (error.response) {
        console.error("Response error details:", error.response.data);
      } else {
        console.error("Error in payment gateway:", error.message);
      }
    }
  };

  const handleAccordionClick = (key) => {
    setActiveKey(key); // Allow opening any accordion by clicking on it
  };

  const verifyEmail = RegformData.email;
    // *************register  verification send otp ************** 💯
  const handleSendOtp = async () => {
    if (!verifyEmail) {
      setEmailError("Please fill in your email.");
      return;
    }
    try {
      const res = await axios.post(
        "https://backend-visa2.vercel.app/api/RegisterOtp",
        {
          verifyEmail,
        }
      );
      if (res.data.success) {
        alert("OTP has been sent to your email.");
      } else {
        alert("Failed to send OTP.");
      }
    } catch (error) {
      if (error.response) {
        // Check if the backend has responded with an error message
        setEmailError(error.response.data.message); // Display the error message
        console.log("Error message from server:", error.response.data.message); // Log the message
      } else {
        console.error("Error sending OTP:", error);
        setEmailError("An error occurred while sending the OTP.");
      }
    }
  };
  // *************register  verification ************** 💯
  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError("Please fill in the OTP.");
      return;
    }

    try {
      const res = await axios.post(
        "https://backend-visa2.vercel.app/api/VerifyRegister",
        {
          verifyEmail,
          otp,
        }
      );
      if (res.data.success) {
        console.log("OTP Verified. You are registered now.");
        alert('OTP Verified. You can registered now.')
      } else {
        // OTP is invalid, show error and prevent registration
        setOtpError("Invalid OTP.");
        console.error("Invalid OTP:", res.data.message);
      }
    } catch (error) {
      // Handle any error coming from the backend (like network issues or validation errors)
      setOtpError(error.response?.data?.message || "Error verifying OTP.");
      console.error("Error verifying OTP:", error);
    }
  };
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="container">
        <div className="Acordian_for_Register">
          <h4>Complete Your Onboarding</h4>
          <p>
            Hey partner, we urge you to fill the complete details, pay platform
            fees and verify your registered email address.
          </p>
          <p className="strong_note_p">
            {" "}
            <strong>Note:</strong> Platform fees of &#8377; 299 will be adjusted
            in your first visa application.{" "}
          </p>
          <Accordion activeKey={activeKey}>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={() => handleAccordionClick("0")}>
                Register
              </Accordion.Header>
              <Accordion.Body>
                <div className="main_wrapper_register11">
                  <div className="main_wrapper_register">
                    <div closeButton className="crossButton googleLogin11">
                      <h2>Register</h2>
                    </div>
                    <div>
                      <div className="googleLogin">
                        <p className="create_your_account">
                          Create Your Account
                        </p>
                        <form>
                          <div className="input_group mb-4">
                            <div className="row">
                              <div className="col-lg-6 fName">
                                <input
                                  className="inputPass55"
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="First Name"
                                  name="name"
                                  value={RegformData.name}
                                />
                                {errors.name && (
                                  <p className="style01">{errors.name}</p>
                                )}
                              </div>
                              <div className="col-lg-6 lName">
                                <input
                                  className="inputPass55"
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Last Name"
                                  name="lname"
                                  value={RegformData.lname}
                                />
                                {errors.lname && (
                                  <p className="style01">{errors.lname}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="input_group mb-4">
                            <input
                              className="inputPass"
                              onChange={handleChange}
                              type="text"
                              placeholder="Mobile No."
                              name="Cnomber"
                              value={RegformData.Cnomber}
                            />
                            {errors.Cnomber && (
                              <p className="style01">{errors.Cnomber}</p>
                            )}
                          </div>
                          <div className="input_group mb-4">
                            <input
                              className="inputPass"
                              onChange={handleChange}
                              type="text"
                              placeholder="Company Name"
                              name="Cname"
                              value={RegformData.Cname}
                            />
                            {errors.Cname && (
                              <p className="style01">{errors.Cname}</p>
                            )}
                          </div>
                          <div className="input_group  mb-4">
                            <div className="input_forVerifyOtp">
                              <input
                                className="inputPass"
                                onChange={handleChange}
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={RegformData.email}
                              />
                              <Button
                                className="btn56 btn_pay"
                                onClick={handleSendOtp}
                                variant="contained"
                                size="small"
                              >
                                Send OTP
                              </Button>
                            </div>
                            {emailError && <p className="error_message">{emailError}</p>}{" "}
                            {errors.email && (
                              <p className="style01">{errors.email}</p>
                            )}
                          </div>
                          <div className="input_group  mb-4">
                            <div className="input_forVerifyOtp">
                              <input
                                className="inputPass"
                                onChange={handleOtpChange}
                                type="text"
                                placeholder="Fill OTP"
                                name="email"
                              />
                              <Button
                                className="btn56 btn_pay"
                                onClick={handleVerifyOtp}
                                variant="contained"
                                size="small"
                              >
                                Verify OTP
                              </Button>
                            </div> 
                            {otpError && <p className="error_message">{otpError}</p>}{" "}
                          </div>

                          <div className="input_group mb-4">
                            <input
                              className="inputPass"
                              onChange={handleChange}
                              type="password"
                              name="password"
                              placeholder="Password"
                              value={RegformData.password}
                            />
                            {errors.password && (
                              <p className="style01">{errors.password}</p>
                            )}
                          </div>
                          <button
                            className="btn56"
                            // type="submit"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={() => handleAccordionClick("1")}>
                Payment
              </Accordion.Header>
              <Accordion.Body>
                <section id="section22" className="Section_payPage">
                  <div className="main_paymentPage">
                    <div className="Paynow_page">
                      <h2>Pay Now</h2>
                      <p>
                        Platform fees of &#8377; 299 will be adjusted in your
                        first visa application.
                      </p>
                      <p>
                        <Link
                          onClick={handlePaymentGetway}
                          style={{
                            color: "red",
                            fontWeight: "600",
                            textDecorationLine: "underline",
                          }}
                        >
                          Pay Now{" "}
                        </Link>
                      </p>
                    </div>
                  </div>
                </section>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Register;
