import React, { useState, useEffect, useContext } from "react";
import "./register.css";
import { useNavigate } from "react-router-dom";
import VisaContext from "../../../context/visa-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterPaySuccess = () => {
  const [RegformData, setRegformData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const ctx = useContext(VisaContext);

  // ******************Register Form Api Calling******************* 💯
  useEffect(() => {
    // Retrieve the state from sessionStorage when the component mounts
    const savedRegformData = sessionStorage.getItem("RegformData");
    if (savedRegformData) {
      const parsedData = JSON.parse(savedRegformData);
      if (Object.keys(parsedData).length > 0) {
        setRegformData(parsedData); // Set only if saved data is not empty
      }
    }
  }, []);

  // This useEffect will run when RegformData is updated
  useEffect(() => {
    // Only call handleSubmitRigister if RegformData is fully populated
    if (RegformData && isFormDataValid(RegformData)) {
      handleSubmitRigister();
    }
  }, [RegformData]);

  // Function to check if RegformData is valid and not empty
  const isFormDataValid = (formData) => {
    return (
      formData.name &&
      formData.lname &&
      formData.Cnomber &&
      formData.email &&
      formData.Cname &&
      formData.password
    );
  };

  const handleSubmitRigister = async (e) => {
    if (e) e.preventDefault(); // Check if e is defined before calling preventDefault()
    ctx.setLoading(true);

    const formData = {
      name: RegformData.name,
      lname: RegformData.lname,
      Cnomber: RegformData.Cnomber,
      email: RegformData.email,
      Cname: RegformData.Cname,
      password: RegformData.password,
    };
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { message, userId } = data;
        console.log("Received message:", message);
        localStorage.setItem("userId", userId);
        // Automatically call login API after successful registration
        await handleSubmitLogin(formData.email, formData.password);

        // Clear sessionStorage data after successful registration
        sessionStorage.removeItem("RegformData");

        ctx.setLogin(true);
        showToastMessage(message, "success");
        setTimeout(() => {
          navigate("/");
        }, 6000);
      } else {
        const errorData = await response.json();
        console.log("errorData", errorData);
        console.log("errorData", errorData.message);
        showToastMessage(errorData.message, "error"); // Show error toast
      }
    } catch (error) {
      ctx.setLoading(false);
      console.error("Registration error:", error);
    } finally {
      ctx.setLoading(false);
    }
    setRegformData({
      name: "",
      lname: "",
      email: "",
      Cname: "",
      password: "",
      Cnomber: "",
    });
  };

  const handleSubmitLogin = async (email, password) => {
    const loginData = { email, password };
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        const id = localStorage.getItem("userId");
        if (id !== data.userId) {
          localStorage.removeItem("userId");
          localStorage.setItem("userId", data.userId);
        }
      } else {
        const errorData = await response.json();
        setErrors({ ...errors, login: errorData.message });
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  // Reusable function to show toast based on the message type (success or error)
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-center",
        toastId: "success-toast", // Unique toast ID for success
        className: "custom-toast-success", // Custom CSS class for success
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-center",
        toastId: "error-toast", // Unique toast ID for error
        className: "custom-toast-error", // Custom CSS class for error
      });
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="Payment_and_emailVerify">
        <div className="main_paymentPage">
          <ToastContainer
            toastId="modal-toast"
            autoClose={5000}
            position="top-center"
            className="custom-toast-container" // Apply custom class to the container
            style={{ zIndex: 1050 }} // Ensure it appears on top of the modal
          />
          <div className="payment-success-img">
            <img src="/NewImage/payment-successful.webp" alt="payment-image" />
          </div>
          <div className="Paynow_page">
            <h2>Payment has been successful. Start Exploring the world with Vijatour. </h2>          

          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPaySuccess;
